import { catchError, finalize } from 'rxjs/operators';
// RxJS
import { map } from 'rxjs/operators';
// CRUD
import { BaseDataSource } from '../../../_base/crud';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { OrderService } from '../_services';

export class OrderDataSource extends BaseDataSource {
	
	private loadingSubject = new BehaviorSubject<boolean>(false);
	private isPreloadTextViewedSubject = new BehaviorSubject<boolean>(true);

	public loading$ = this.loadingSubject.asObservable();
	public isPreloadTextViewed$ = this.isPreloadTextViewedSubject.asObservable();

	public noRecords = new BehaviorSubject<boolean>(false);
	public noRecords$ = this.noRecords.asObservable();

	constructor(private orderService: OrderService) {
		super();
	}
	

	loadOrders(orderFilterList) {
		this.loadingSubject.next(true);

		this.orderService.findOrders(orderFilterList)
			.pipe(
				map(
					orders => {
						if(orders.data == null){
							this.noRecords.next(true);
							this.paginatorTotalSubject.next(orders);
							this.entitySubject.next(orders);
						}else{
							this.paginatorTotalSubject.next(orders.data.TotalRecords);
							this.entitySubject.next(orders.data.Items);
							this.noRecords.next(false)
						}
					}
				),
				catchError(() => of([])),
				finalize(() => {
					this.loadingSubject.next(false);
					this.isPreloadTextViewedSubject.next(false);
				})
			)
			.subscribe();
	}


	loadOrderDetails(orderId) {
		this.loadingSubject.next(true);

		this.orderService.findOrderDetails(orderId)
			.pipe(
				map(
					orders => {
						this.entitySubject.next(orders.data);
					}
				),
				catchError(() => of([])),
				finalize(() => {
					this.loadingSubject.next(false);
					this.isPreloadTextViewedSubject.next(false);
				})
			)
			.subscribe();
	}

	loadOrderAmountMasterDetails(orderId) {
		this.loadingSubject.next(true);

		return this.orderService.findOrderAmount(orderId)
			.pipe(
				catchError(() => of([])),
				finalize(() => {
					this.loadingSubject.next(false);
					this.isPreloadTextViewedSubject.next(false);
				})
			)
	}
}