import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
// RxJS
import { Observable } from 'rxjs';
import { map, catchError, tap } from 'rxjs/operators';
import { ExcelService } from '../../../_base/crud/services/excel.service';

@Injectable({
	providedIn: 'root'
})
export class OrderService {


	constructor(private http: HttpClient, public excelService: ExcelService) { }

	findOrders(orderFilterList): Observable<any> {
		return this.http.get('api/CRM/Orders?From=' + orderFilterList.From + '&StatusIds=' + orderFilterList.StatusIds + '&Text=' + orderFilterList.Text + '&To=' + orderFilterList.To + '&UserIds=' + orderFilterList.UserIds + '&toDate=' + orderFilterList.toDate + '&fromDate=' + orderFilterList.fromDate + '&minOrderAmount=' +orderFilterList.minOrderAmount +'&maxOrderAmount='+ orderFilterList.maxOrderAmount + '&CountryId='+orderFilterList.CountryId + '&StateId='+orderFilterList.StateId +'&CityId='+orderFilterList.CityId)
			.pipe(
				map(res => res)
			);
	}

	findOrderDetails(orderId: number): Observable<any> {
		return this.http.get('api/CRM/OrderDetail?orderId=' + orderId)
			.pipe(
				map(res => res)
			);
	}

	findOrderAmount(orderId: number): Observable<any>{
		return this.http.get('api/CRM/OrderMasterDetail?orderId=' + orderId)
		.pipe(
			map(res => res)
		);
	}

	orderStatusChange(orderStatusId: number, orderId: number, comment: string): Observable<any> {
		const params =
		{
			'Comments': comment,
			'OrderId': orderId,
			'StatusId': orderStatusId
		};
		return this.http.post('api/CRM/Order/' + orderId + '/Status', params);
	}
	fileExport(orderList): Observable<any> {
		let para = {
			From: 1,
			text: orderList.Text,
			fromDate: orderList.fromDate,
			toDate: orderList.toDate,
			minOrderAmount: orderList.minOrderAmount,
			maxOrderAmount: orderList.maxOrderAmount,
			StatusIds: orderList.StatusIds,
			To: -1,
			userIds: orderList.UserIds,
		}
		let x = new HttpParams().set('From',para.From.toString()).set('text',para.text).set('fromDate',para.fromDate).set('toDate',para.toDate).set('minOrderAmount',para.minOrderAmount).set('maxOrderAmount',para.maxOrderAmount).set('StatusIds',para.StatusIds).set('To',para.To.toString()).set('userIds',para.userIds)
		return this.http.get('api/CRM/Orders/Export?', { params : x,responseType: 'arraybuffer'})
			.pipe(
				map((res) => {
					return res;
				}),
				tap(data => this.excelService.saveAsExcelFile(data, 'Orders'),
					error => console.log(error),
				),
				catchError(err => {
					return null;
				})
			);
	}
	orderDetailsStatusChange(orderStatusId: number, orderId: number, comment: string): Observable<any> {
		const params =
		{
			'Comments': comment,
			'OrderDetailId': orderId,
			'StatusId': orderStatusId
		};
		return this.http.post('api/CRM/Order/' + orderId + '/Detail/Status', params);
	}

	fetchOrderlogs(orderId: number) {
		return this.http.get('api/CRM/Order/' + orderId + '/History');
	}

	updateDiscount(discountDetails): Observable<any> {
		const params =
		{
			'Comments': discountDetails.Comments,
			'OrderId': discountDetails.OrderId,
			'Discount': discountDetails.Discount,
			'ShippingAddress': discountDetails.ShippingAddress,
			'LeadId':discountDetails.LeadId,
			'ParentLeadId':discountDetails.ParentLeadId,
			'OrderStatusId':discountDetails.OrderStatusId,
			'Pincode':discountDetails.Pincode
		}
		// return this.http.post('api/CRM/EditOrder' + discountDetails.OrderId + '/', params);
		return this.http.post('api/CRM/EditOrder', params);
	}

	updateDetailDiscount(discountDetails): Observable<any> {
		const params =
		{
			'Comments': discountDetails.Comments,
			'OrderDetailId': discountDetails.OrderId,
			'Discount': discountDetails.Discount
		}
		return this.http.post('api/CRM/Order/' + discountDetails.OrderId + '/Detail/Discount', params);
	}

	updateDetailQuantity(quantityDetails): Observable<any> {
		const params =
		{
			'Comments': quantityDetails.Comments,
			'OrderDetailId': quantityDetails.OrderId,
			'Quantity': quantityDetails.Discount
		}
		return this.http.post('api/CRM/Order/' + quantityDetails.OrderId + '/Detail/Quantity', params);
	}
	orderPdf(orderId: number): Observable<any> {
		return this.http.get('api/CRM/Order/' + orderId + '/GetPdf');
	}

	quantityDiscount(quantitydiscountDetails , id): Observable<any> {
		console.log(quantitydiscountDetails)
		const data = 
		{
			"OrderDetailId": id,
  			"Quantity": parseInt(quantitydiscountDetails.Quantity),
  			"Discount": parseInt(quantitydiscountDetails.Discounts),
  			"Comments": quantitydiscountDetails.Comment
		}
		return this.http.post(`api/CRM/Order/${id}/Detail`, data)
	}


}
