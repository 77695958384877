import { catchError, finalize } from 'rxjs/operators';
// RxJS
import { map } from 'rxjs/operators';
// CRUD
import { BaseDataSource } from '../../_base/crud';
import { BehaviorSubject, of } from 'rxjs';
import { ExpeseCoverageService } from './expese-coverage.service';

export class ExpenseCoverageDataSource extends BaseDataSource {

    private loadingSubject = new BehaviorSubject<boolean>(false);
    private applyLoadingSubject = new BehaviorSubject<boolean>(false);
    private isPreloadTextViewedSubject = new BehaviorSubject<boolean>(true);

    public loading$ = this.loadingSubject.asObservable();
    public applyLoading$ = this.applyLoadingSubject.asObservable();
    public isPreloadTextViewed$ = this.isPreloadTextViewedSubject.asObservable()


    public noRecords = new BehaviorSubject<boolean>(false);
    public noRecords$ = this.noRecords.asObservable();

    constructor(private coverageService: ExpeseCoverageService) {
        super();
    }

    loadCoverage(data, isApply?) {
        this.loadingSubject.next(true);
        this.isPreloadTextViewedSubject.next(true);
        this.entitySubject.next([]);
        this.coverageService.totalExpenseCoveragecountSubject.next([])
        this.noRecords.next(false)
        if(isApply) {
            this.applyLoadingSubject.next(true);
        }
        this.coverageService.getexpenseDetails(data)
            .pipe(
                map(
                    report => {
                        if (report.data.TotalRecords===0) {
                            this.paginatorTotalSubject.next(0);
                            this.entitySubject.next(report.data.Lists);
                            this.coverageService.totalExpenseCoveragecountSubject.next(report.data.TotalCount);
                            this.noRecords.next(true)
                        } else {                       
                            this.paginatorTotalSubject.next(report.data.TotalRecords);
                            this.entitySubject.next(report.data.Lists);
                            this.coverageService.totalExpenseCoveragecountSubject.next(report.data.TotalCount);
                            this.noRecords.next(false)
                        }
                    }
                ),
                catchError(() => of([])),
                finalize(() => {
                    if(isApply) {
                        this.applyLoadingSubject.next(false);
                    }
                    this.loadingSubject.next(false);
                    this.isPreloadTextViewedSubject.next(false);
                })
            )
            .subscribe();
    }


    loadCoverageid(data, isApply?) {
    	this.loadingSubject.next(true);
        if(isApply) {
            this.applyLoadingSubject.next(true);
        }
    	this.isPreloadTextViewedSubject.next(true);
    	this.entitySubject.next([]);
    	this.coverageService.totalExpenseCoveragecountSubject.next([])
    	this.noRecords.next(false)
    	this.coverageService.getExpenseCoveragereportbyuserid(data)

    		.pipe(
    			map(
    				report => {
    					if (report.data.TotalRecords===0) {
    					this.paginatorTotalSubject.next(0);
    					this.entitySubject.next(report.data.Lists);
    					this.coverageService.totalExpenseCoveragecountSubject.next(report.data.TotalCount);
    					this.noRecords.next(true)
    			}else{
    				this.paginatorTotalSubject.next(report.data.TotalRecords);
    				this.entitySubject.next(report.data.Lists);
    				this.coverageService.totalExpenseCoveragecountSubject.next(report.data.TotalCount);
    				this.coverageService.totalExpenseCoverageUserDetailsSubject.next(report.data.UserDetails);
    				this.noRecords.next(false)
    			}
    		}
    			),
    			catchError(() => of([])),
    			finalize(() => {
    				this.loadingSubject.next(false);
                    if(isApply) {
                        this.applyLoadingSubject.next(false);
                    }
    				this.isPreloadTextViewedSubject.next(false);
    			})
    		)
    		.subscribe();
    }

}