export class UserModel {
	ProductId: number;
	SKU: string;
	ProductName: string;
	MRP: number;
	DealerPrice: number;
	OfferPrice: number;
	AvailableQty: number;
	TotalQty: number;
	Comments: string;
	CreatedBy: string;
	Date: Date;
	Title: string;
	Category: string;
	Description: string;

	clear() {
		this.ProductId = 0;
		this.SKU = '';
		this.ProductName = '';
		this.MRP = 0;
		this.DealerPrice = 0;
		this.OfferPrice = 0;
		this.AvailableQty = 0;
		this.TotalQty = 0;
		this.Comments = '';
		this.CreatedBy = '';
		this.Date = new Date();
		this.Title = '';
		this.Category = '';
		this.Description = '';
		
	}
}