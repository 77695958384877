import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { map, tap, catchError } from "rxjs/operators";
import { ExcelService } from "../../../_base/crud";

@Injectable({
	providedIn: "root",
})
export class OpportunitiesService {
	constructor(private http: HttpClient, private excelService: ExcelService) {}

	chartObj = {
		labels: [],
		data: [],
		total: "",
		reportLabel: "",
		options: {},
		userList: [],
	};

	getopportunitiesbystatus(fromDate: string, userId: number, toDate: string): Observable<any> {
		return this.http.get<any>("api/CRM/Report/Opportunities/Status?FromDate=" + fromDate + "&SalesUserId=" + userId + "&ToDate=" + toDate).pipe(
			map((data) => {
				let Report: any = [],
					reportData: any = [];
				this.Initialise();
				Report = data.data;
				for (let report of Report) {
					if (report.Value > 0) {
						this.chartObj.labels.push(report.Status);
						reportData.push(report.Value);
						this.chartObj.data[0] = reportData;
						this.chartObj.userList.push(report);
					}
				}
				return this.chartObj;
			})
		);
	}
	getOpportunitiesbyTeam(fromDate: string, toDate: string): Observable<any> {
		return this.http.get<any>("api/CRM/Report/Opportunities/Team?FromDate=" + fromDate + "&ToDate=" + toDate).pipe(
			map((data) => {
				let Report: any = [],
					reportData: any = [];
				this.Initialise();
				Report = data.data;
				for (let report of Report) {
					if (report.Value > 0) {
						this.chartObj.labels.push(report.Name);
						reportData.push(report.Value);
						this.chartObj.data[0] = reportData;
						this.chartObj.userList.push(report);
					}
				}
				return this.chartObj;
			})
		);
	}
	getOpportunitiesbyTeamWithUserId(fromDate: string, userId: number, toDate: string): Observable<any> {
		return this.http.get<any>("api/CRM/Report/Opportunities/Team?FromDate=" + fromDate + "&SalesUserId=" + userId + "&ToDate=" + toDate).pipe(
			map((data) => {
				let Report: any = [],
					reportData: any = [];
				this.Initialise();
				Report = data.data;
				for (let report of Report) {
					if (report.Value > 0) {
						this.chartObj.labels.push(report.Name);
						reportData.push(report.Value);
						this.chartObj.data[0] = reportData;
						this.chartObj.userList.push(report);
					}
				}
				return this.chartObj;
			})
		);
	}

	fileExport(fromDate: string, userId: number, toDate: string, from: string): Observable<any> {
		return this.http.get("api/CRM/Report/Opportunities/Team/Export?FromDate=" + fromDate + "&SalesUserId=" + userId + "&ToDate=" + toDate, { responseType: "arraybuffer" }).pipe(
			map((res) => {
				return res;
			}),
			tap(
				(data) => this.excelService.saveAsExcelFile(data, "OpportunitiesReport"),
				(error) => console.log(error)
			),
			catchError((err) => {
				return null;
			})
		);
	}

	getTeamPerformance(userId: number, fromDate: any, toDate: any): Observable<any> {
		return this.http.get("api/CRM/TeamPerformance?userId=" + userId + "&fromDate=" + fromDate + "&toDate=" + toDate).pipe(map((res) => res));
	}

	Initialise() {
		this.chartObj = {
			labels: [],
			data: [],
			total: "",
			reportLabel: "",
			options: {},
			userList: [],
		};
	}

	customerAssigned(from: number, to: number, fromDate: any, search: any, toDate: any, userId: any) :Observable<any>{
		let params = {
			UserId: userId,
			FromDate: fromDate,
			ToDate: toDate,
			From: from,
			To: to,
			SearchText: search,
    };
    

		return this.http.post<any>('api/CRM/TeamPerformance/CustomerDetails', params).pipe(map((res) => {
			return res	
	} ));
	}
	opportunityAssigned(from: number, to: number, fromDate: any, search: any, toDate: any, userId: any) :Observable<any> {
		let params = {
			UserId: userId,
			FromDate: fromDate,
			ToDate: toDate,
			Status: "all",
			From: from,
			To: to,
			SearchText: search,
    };
    return this.http.post<any>('api/CRM/TeamPerformance/OpportunitiesDetails',params).pipe(map((res) =>  res));
	}
	opportunityWon(from: number, to: number, fromDate: any, search: any, toDate: any, userId: any) :Observable<any> {
		let params = {
			UserId: userId,
			FromDate: fromDate,
			ToDate: toDate,
			Status: "won",
			From: from,
			To: to,
			SearchText: search,
    };
    return this.http.post<any>('api/CRM/TeamPerformance/OpportunitiesDetails',params).pipe(map((res) => res));
	}
	opportunityLost(from: number, to: number, fromDate: any, search: any, toDate: any, userId: any)  :Observable<any>{
		let params = {
			UserId: userId,
			FromDate: fromDate,
			ToDate: toDate,
			Status: "lost",
			From: from,
			To: to,
			SearchText: search,
    };
    return this.http.post<any>('api/CRM/TeamPerformance/OpportunitiesDetails',params).pipe(map((res) => res));
	}
	getCustomerList(params): Observable<any> {
		return this.http.post('api/CRM/Leads', params)
		  .pipe(map(
			res => res
		  ))
	  }
	
	  getTagList(): Observable<any> {
		return this.http.get('api/CRM/Tags')
		  .pipe(map(
			res => res
		  ))
	}
	getTeam(): Observable<any> {
		const params = {
		  From: 1,
		  To: -1,
		  Text: '',
		}
		return this.http.post('api/CRM/MyTeam', params)
	  }
}
