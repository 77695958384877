import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ScheduleReportService {

  constructor(
    private http: HttpClient
  ) { }

  getScheduleReport(): Observable<any>{
    return this.http.get('api/schedulereports')
      .pipe(
        map(res => res)
      );
  }

  createScheduleReport(reportDetails): Observable<any>{
    return this.http.post('api/schedulereports', reportDetails)
      .pipe(
        map(res => res)
      );
  }

  updateScheduleReport(Id, reportDetails): Observable<any>{
    return this.http.post('api/schedulereports/' + Id, reportDetails)
      .pipe(
        map(res => res)
      );
  }

  getScheduleReportGlobal():Observable<any>{
    return this.http.get('api/ScheduleReportsGlobalSettings')
    .pipe(
      map(res => res)
    );
  }

  updateScheduleReportGlobal(reportDetails): Observable<any>{
    return this.http.put('api/ScheduleReportsGlobalSettings', reportDetails)
      .pipe(
        map(res => res)
      );
  }
}
