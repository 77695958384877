import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http'
import { BehaviorSubject, Observable } from 'rxjs';
import { map, tap, catchError } from 'rxjs/operators';
import { ExcelService } from '../../_base/crud';

@Injectable({
  providedIn: 'root'
})
export class ExpeseCoverageService {
  public totalExpenseCoveragecountSubject = new BehaviorSubject<any>([]);
  public totalExpenseCoverageUserDetailsSubject = new BehaviorSubject<any>([]);

  constructor(
    private http: HttpClient,
    private excelService: ExcelService
  ) { }


  getexpenseDetails(data): Observable<any> {
    const params = {
      "UserId": data.userId,
      "From": data.From,
      "To": data.To,
      "PendingAmount": data.pendingAmount,
      "PendingReimbursementAmount": data.pendingReimbursementAmount,
      "ApprovedAmount": data.approvedAmount,
      "ManagerIds": data.ManagerIds,
      "UserIds": data.UserIds,
      "StartDate": data.startDate,
      "EndDate": data.endDate,
      "Search": data.Text,
      "SortColumn": data.SortColumn,
      "SortOrder": data.SortOrder,
      "IsExport": false,
    }
    return this.http.post<any>('api/ExpenseCoverageReport/Team', params)
      .pipe(
        map(data => {
          return data;
        })
      );
  }

  expensecoverageExport(data) {
    return this.http.post('api/ExpenseCoverage/Team/Export', data, { responseType: 'arraybuffer' })
      .pipe(map((res) => {
        return res;
      }),
        tap(
          data => this.excelService.saveAsExcelFile(data, 'ExpenseCoverageReport'),
          error => console.log(error),
        ),
        catchError(err => {
          return null;
        })
      )
  }

  expenseemailsend(data) {
    return this.http.post('api/ExpenseCoverage/Team/Export', data, { responseType: 'arraybuffer' })
      .pipe(map((res) => {
        return res;
      }),
        
        catchError(err => {
          return null;
        })
      )
  }

  fileExport(fromDate: string, toDate: string, from: string, managerIds, userIds): Observable<any> {
    let url = ''
    if (from == "salesbyteam") {
      url = 'api/CRM/Report/Sales/Team/Export'
    }
    else if (from == "salesbycustomer") {
      url = 'api/CRM/Report/Sales/ByCustomer/Export'
    }
    else if (from == 'salesbyproduct') {
      url = 'api/CRM/Report/Sales/ByProduct/Export'
    }
    return this.http.get(url + '?FromDate=' + fromDate + '&ToDate=' + toDate + '&managerIds=' + managerIds + '&userIds=' + userIds, { responseType: 'arraybuffer' })
      .pipe(map((res) => {
        return res;
      }),
        tap(
          data => this.excelService.saveAsExcelFile(data, 'SalesReport'),
          error => console.log(error),
        ),
        catchError(err => {
          return null;
        })
      )
  }

  expenseCoverageDetailsExport(data) {
    return this.http.post('api/ExpenseCoverage/User/Export', data, { responseType: 'arraybuffer' })
      .pipe(map((res) => {
        return res;
      }),
        tap(
          data => this.excelService.saveAsExcelFile(data, 'ExpenseCoverageDetailsReport'),
          error => console.log(error),
        ),
        catchError(err => {
          return null;
        })
      )
  }

  getExpenseCoveragereportbyuserid(reportbyuserid): Observable<any> {
    const params = {
      "UserId": reportbyuserid.UserId,
      "From": reportbyuserid.From,
      "To": reportbyuserid.To,
      "PendingAmount": reportbyuserid.PendingAmount,
      "PendingReimbursementAmount": reportbyuserid.PendingReimbursementAmount,
      "ApprovedAmount": reportbyuserid.ApprovedAmount,
      // "ManagerIds": reportbyuserid.ManagerIds,
      "UserIds": reportbyuserid.UserIds,
      "StartDate": reportbyuserid.FromDate,
      "EndDate": reportbyuserid.ToDate,
      // "Search": reportbyuserid.Text,
      "SortColumn": reportbyuserid.SortColumn,
      "SortOrder": reportbyuserid.SortOrder,
      "IsExport": false,
    }
    return this.http.post('api/ExpenseCoverageReport/User', params)
      .pipe(
        map(res => res)
      );
  }

  getbyUserIdDetails(userId: any): Observable<any> {
    return this.http.get(`api/Users/${userId}`)
      .pipe(
        map(res => res)
      );
  }

}
