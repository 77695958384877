import { catchError, finalize } from 'rxjs/operators';
// RxJS
import { map } from 'rxjs/operators';

// CRUD
import { BaseDataSource } from '../../_base/crud';
import { BehaviorSubject, of } from 'rxjs';
import { UserService } from '../_services';

export class UserDataSource extends BaseDataSource {
	
	private loadingSubject = new BehaviorSubject<boolean>(false);
	private isPreloadTextViewedSubject = new BehaviorSubject<boolean>(true);

	public loading$ = this.loadingSubject.asObservable();
	public isPreloadTextViewed$ = this.isPreloadTextViewedSubject.asObservable();

	constructor(private userService: UserService) {
		super();
	}

	loadUsers(Data) {
		// console.log('weruo',Data);
		this.loadingSubject.next(true);

		this.userService.findUsers(Data)
			.pipe(
				map(
					users => {
						this.paginatorTotalSubject.next(users.data.TotalRecords);
						this.entitySubject.next(users.data.Items);
					}
				),
				catchError(() => of([])),
				finalize(() => {
					this.loadingSubject.next(false);
					this.isPreloadTextViewedSubject.next(false);
				})
			)
			.subscribe();
	}

	loadBulkUploadFileName(loadtype,from,to,search) {
		this.loadingSubject.next(true);

		this.userService.ShowBulkUploadUserFileName(loadtype,from,to,search)
			.pipe(
				map(
					users => {
						this.paginatorTotalSubject.next(users.TotalRecords);
						this.entitySubject.next(users.Items);
					}
				),
				catchError(() => of([])),
				finalize(() => {
					this.loadingSubject.next(false);
					this.isPreloadTextViewedSubject.next(false);
				})
			)
			.subscribe();
	}

	loadBulkUploadViewReports(File_Id,from,to,search) {
		this.loadingSubject.next(true);

		this.userService.ShowBulkUploadViewReport(File_Id,from,to,search)
			.pipe(
				map(
					users => {
						this.paginatorTotalSubject.next(users.TotalRecords);
						this.entitySubject.next(users.Items);
					}
				),
				catchError(() => of([])),
				finalize(() => {
					this.loadingSubject.next(false);
					this.isPreloadTextViewedSubject.next(false);
				})
			)
			.subscribe();
	}
}