// Angular
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
// RxJS
import { Observable } from 'rxjs';
// Models
import { RolesModel } from "../models/roles.model";
import { map} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class RolesService {

  constructor(private http: HttpClient) { }
    
    
	findRoles(): Observable<any> {
	
		return this.http.get('api/Roles')
			.pipe(
				map(res => res)
			);
	}

	// add Roles
	addRole(roles): Observable<RolesModel> {
		return this.http.post<RolesModel>('api/Roles', roles);
	}
	//update
	updateRole(roles: RolesModel): Observable<RolesModel> {
		return this.http.put<RolesModel>('api/Roles', roles);
	}
	

	// delete Roles
	deleteRole(roleId: number): Observable<RolesModel> {
		return this.http.delete<RolesModel>('api/Roles/' + roleId);
	}

	
	//select parent Roles
	getSelectrole():Observable<any>{
		return this.http.get<any>('api/Roles')
			.pipe(map(resp => {
				return resp.data;
			}));
	}

	//Get Permissions
	getPermissions(roleId): Observable<any>{
		return this.http.get('api/Roles/' + roleId + '/Permissions')
			.pipe(map(res => res));
	}

	//Update Permissions
	updatePermission(permissionAdded, permissionsDeleted, roleId): Observable<any>{
		const params = {
			PermissionsAdded: permissionAdded,
			PermissionsDeleted: permissionsDeleted,
			RoleId: roleId
		}
		return this.http.put('api/Roles/Permissions', params)
			.pipe(
				map(res => res)
			);
	}
}
